import React, { Component } from "react";
export default class Porfolio extends Component {
	render() {
		let resumeData = this.props.resumeData;
		return (
			<section id="portfolio">
				<div className="row">
					<div className="twelve columns collapsed">
						<h1>Take a look at my projects</h1>
						<div
							id="portfolio-wrapper"
							className="bgrid-quarters s-bgrid-thirds cf"
						>
							<a href="http://sc.nothings.dev">
								<div className="columns portfolio-item">
									<div className="item-wrap">
										<img
											src="images/portfolio/super_caster_image.png"
											className="item-img"
										/>
										<div className="overlay">
											<div className="portfolio-item-meta">
												<h5>Super Caster</h5>
												<p>
													Dungeons and Dragons
													character creation app.
													{/* ROLE: creator */}
												</p>
											</div>
										</div>
									</div>
								</div>
							</a>

							<a href="https://gr.nothings.dev">
								<div className="columns portfolio-item">
									<div className="item-wrap">
										<img
											src="images/portfolio/gr_image.png"
											className="item-img"
										/>
										<div className="overlay">
											<div className="portfolio-item-meta">
												<h5>Groovy Records</h5>
												<p>
													School group project for
													creating a MySQL database
													application.
													{/* ROLE: frontend and backend developer */}
												</p>
											</div>
										</div>
									</div>
								</div>
							</a>

							<a href="https://wall.nothings.dev">
								<div className="columns portfolio-item">
									<div className="item-wrap">
										<img
											src="images/portfolio/wall_image.png"
											className="item-img"
										/>
										<div className="overlay">
											<div className="portfolio-item-meta">
												<h5>Wall</h5>
												<p>
													Persistent and concurrent
													chat application.
													{/* ROLE: creator */}
												</p>
											</div>
										</div>
									</div>
								</div>
							</a>

							{/* {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(
                <div className="columns portfolio-item">
                  <div className="item-wrap">
                    <a href="#modal-01">
                      <img src={`${item.imgurl}`} className="item-img"/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )
            })
          } */}
						</div>
					</div>
				</div>
			</section>
		);
	}
}
