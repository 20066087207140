let resumeData = {
    "imagebaseurl":"https://github.com/krbylit",
    "name": "Kirby Little",
    "role": "IT support worker and CS student",
    "linkedinId":"https://www.linkedin.com/in/kirbylittle/",
    "skypeid": "Your skypeid",
    "roleDescription": "I'm a former university lecturer pursuing a career in programming.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/kirbylittle/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/krbylit",
          "className":"fa fa-github"
        },
        // {
        //   "name":"skype",
        //   "url":"",
        //   "className":"fa fa-twitter"
        // }
      ],
    "aboutme":"I am a Computer Science student at Oregon State University. I previously taught English at Milwaukee Area Technical College and the University of Nebraska-Lincoln.",
    "address":"Omaha, NE",
    "email":"krbylit@gmail.com",
    "education":[
            {
        "UniversityName":"Computer Science B.S.",
        "specialization":"Oregon State University",
        "MonthOfPassing":"Mar",
        "YearOfPassing":"2024",
        // "Achievements":"GPA: 3.9"
      },
      {
        "UniversityName":"English M.A.",
        "specialization":"University of Nebraska-Lincoln",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2017",
        // "Achievements":"UNL Parents' Recognition Award"
      },
      {
        "UniversityName":"Philosophy B.A.",
        "specialization":"University of Nebraska-Lincoln",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2012",
        // "Achievements":"GPA: 3.5"
      }
    ],
    "work":[
      {
        "CompanyName":"Omaha Public Schools",
        "specialization":"Building Technical Support",
        "MonthOfLeaving":"Current",
        "YearOfLeaving":"",
        "Achievements":""
      },
      {
        "CompanyName":"University of Nebraska-Lincoln",
        "specialization":"Learning Spaces Support",
        "MonthOfLeaving":"January",
        "YearOfLeaving":"2023",
        "Achievements":""
      },
      {
        "CompanyName":"Milwaukee Area Technical College",
        "specialization":"Instructor",
        "MonthOfLeaving":"Oct",
        "YearOfLeaving":"2021",
        "Achievements":""
      },
      {
        "CompanyName":"University of Nebraska-Lincoln",
        "specialization":"Lecturer",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2019",
        "Achievements":""
      }
    ],
    "skillsDescription":"",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"React"
      },
      {
        "skillname":"Express"
      },
      {
        "skillname":"Django"
      },
      {
        "skillname":"Python"
      },
    ],
    "portfolio":[
      {
        "name":"Pantri",
        "description":"AI assisted grocery management application",
        "imgurl":"images/portfolio/pantri.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData