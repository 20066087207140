import React, { Component } from "react";
export default class ContactUs extends Component {
	render() {
		let resumeData = this.props.resumeData;
		return (
			<section id="contact">
				<div className="row section-head">
					<div className="ten columns">
						<p className="lead">
							{" "}
							<a href="mailto:krbylit@gmail.com">
								krbylit@gmail.com
							</a>
						</p>
						<p className="lead">
							<a
								href="docs/kirby.little_resume_2022.pdf"
								download
							>
								Download
							</a>{" "}
							full resume PDF
						</p>
					</div>
				</div>

				{/* <form name="Contact Form" method="POST" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
					<p>
						<label for="name">
							Name: <input type="text" name="name" id="name"/>
						</label>
					</p>
					<p>
						<label for="inputEmail">
							Email: <input type="email" name="email" id="inputEmail"/>
						</label>
					</p>
					<p>
						<label for="message">
							Message: <textarea name="message" id="message"></textarea>
						</label>
					</p>
					<br />
					<p>
						<button type="submit">Send</button>
					</p>
          <div data-netlify-recaptcha="true"></div>
				</form> */}

				{/* <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4><a href="mailto:krbylit@gmail.com">Email me</a>
                  Linked in :
                  {resumeData.linkedinId}
                </h4>
              </div>
            </aside>
          </div> */}
			</section>
		);
	}
}
