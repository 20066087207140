import React, { Component } from 'react';
export default class ModalPantri extends Component {
  render() {
    return (
        <section id='modal-01'>
      <div className='row'>
        <a href="https://pantri-production.up.railway.app/"><img src="images/portfolio/pantri.jpg"/></a>
      </div>
      </section>
    );
  }
}